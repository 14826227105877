import React,{useRef,useState,useEffect,useContext} from 'react'
import { useParams,Link } from 'react-router-dom'
import axios from 'axios'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Table,Modal,Row,Col,Badge} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faVideo } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import loading from './loading.gif'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,Cell, Legend, LabelList,ResponsiveContainer } from 'recharts';
import {Box} from '@mui/material';
import { Paper} from '@mui/material';


export default function DashboardSearchedPositions() {
    const [wallOfFame,setWallOfFame]=useState([])
    const [data,setData]=useState([])
    const [dataTot,setDataTot]=useState(0)
    const [isLoading,setIsLoading]=useState(true)

    const colors = ['#9971E1', '#51C2D5', '#62B56D', '#F06456', '#4b73ef', '#A0A0A0', '#FF5733'];


    const getXAxisDomain = (data) => {
      const maxValue = Math.max(...data.map(item => item.value));
      const step = 10;  // το επόμενο βήμα για το όριο του άξονα
      const roundedMax = Math.ceil(maxValue / step) * step;  // στρογγυλοποιεί το maxValue στο επόμενο πολλαπλάσιο του step
      return [0, roundedMax + step];  // το +step εξασφαλίζει ότι το μέγιστο όριο του άξονα είναι λίγο μεγαλύτερο
    };

    const xAxisDomain = getXAxisDomain(data); 

    useEffect(()=>{
        loadData()
    },[])

    const loadData=()=>{
        //setIsLoading(true)
        axios.get(process.env.REACT_APP_API_SERVER+'manager/dashboard/searchedpositions/'+sessionStorage.getItem('userToken'),{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
              }            
          })
        .then(res=>{
            //console.log(res.data)
            setIsLoading(false)
            setData(res.data.results)
        }).catch(err=>{
            setIsLoading(false)
    
        }) 
      }  

      function getRandomEntry(arr) {
        const randomIndex = Math.floor(Math.random() * arr.length);
        return arr[randomIndex];
      }

  return (
    <>

    <Paper elevation={3} sx={{ padding: 4, width:"100%", minHeight: '520px', height:"auto" }}>
      <div style={{  backgroundColor: '#fff', borderRadius: '8px', padding: '10px'}}> 
        <h4 style={{ fontSize: '1rem', fontWeight: 600 }}>Positions you search more</h4>

            <Box sx={{width: '100%',height: { xs: 400,sm:370, md: 400 }, display:"flex", gap:"30px", flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems:"center"}}>
              <ResponsiveContainer width="100%" height="80%" >
                <BarChart
                  layout="vertical"
                  data={data}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    {/* Επηρεαζει το gradient των χρωματων ωστε να φαινονται πιο ομορφα */}
                    <defs>
                      {data.map((entry, index) => (
                        <linearGradient
                          key={`gradient-${index}`}
                          id={`gradient-${index}`}
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop offset="0%" stopColor={colors[index % colors.length]} stopOpacity={0.9} />
                          <stop offset="100%" stopColor={colors[index % colors.length]} stopOpacity={0.5} />
                        </linearGradient>
                      ))}
                    </defs>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" domain={xAxisDomain} tickFormatter={(value) => `${value}%`} />
                  <YAxis dataKey="label" type="category"  tick={{ fontSize: 12, fontWeight:"bold" }}/>
                  <Tooltip cursor={{ fill: '#FFFAE5' }} />
                  <Bar
                    barSize={40}
                    dataKey="value"
                    label={({ x, y, value, index }) => (
                      <g transform={`translate(${x + 10},${y + 20})`} textAnchor="left" fontSize={12}>
                        <text fill="white">{data[index].label}</text> {/* Το position label */}
                        <text fill="white" dx={100}>{`${value}%`}</text> {/* Το value label */}
                      </g>
                    )}
                    style={{ filter: 'drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.21))' }}
                    >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={`url(#gradient-${index})`} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>

              <Box sx={{marginLeft:"40px", display: 'block', justifyContent: 'center', alignItems:"center", gap: '20px', width: { xs: '100%', md: 'auto' }  }}>
                {data.map((item, index) => (
                  <div key={item.label} style={{ display: 'flex', alignItems: 'center', gap: '5px', width:"200px" }}>
                      <span style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: colors[index % colors.length]
                      }}></span>
                      <div>
                        <span>{item.label} </span>
                        <span>{item.value}%</span>
                      </div>
                  </div>
                ))}
              </Box>
            </Box>
      </div>
    </Paper>







              {/* <Card style={{ width: '100%' ,boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',border: '1px solid #f0f0f0', marginTop:"50px"}}>
              <Card.Body>
                <Card.Title>Positions you searched more</Card.Title>
                <Card.Text>
                  {!isLoading &&
                  <div style={{ width: '100%', height: 400 }}>
                      <ResponsiveContainer width="100%" height="100%">
                      <BarChart

                    data={data}
                    layout="vertical"
                    margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
                    >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis type="number" domain={[0,100]} tickFormatter={(value) => `${value}%`}/>
                          <YAxis type="category"  dataKey="label" hide={true}/>
                          <Bar dataKey="value" fill="#82ca9d" barSize={25}>
                          <LabelList dataKey="label" position="inside" fill="white" />
                          </Bar>
                      </BarChart>
                      </ResponsiveContainer>
                      </div>

                  }
                  {isLoading &&
                    <p align="center"><img src={loading} width="200"/></p>
                    
                  }
                </Card.Text>
              </Card.Body>
            </Card> */}
  </>
  )
}
