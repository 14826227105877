import React,{useContext, useEffect,useState,useRef} from 'react'
import axios from 'axios'
import {Link,NavLink,useNavigate,useParams } from 'react-router-dom'
import {UserContext} from '../context/UserContext'
import {Alert,Container,Form,Button,Card,Navbar,NavDropdown,Nav,Table,Modal,Row,Col} from 'react-bootstrap';
import logo from '../logo.png'
import { MultiSelect } from "react-multi-select-component";
import moment from 'moment';
import DataTable from 'react-data-table-component';
import exportFromJSON from 'export-from-json' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping,faEdit,faShop,faPlus, faShoppingBasket,faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import loading from './loading.gif'
import { countries} from 'countries-list'

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

export default function Profile() {
    const {isAuth,setIsAuth}=useContext(UserContext)
    const {apiToken,setApiToken} = useContext(UserContext)
    const {userID,setUserID }= useContext(UserContext)
    const {usrPhoto,setUsrPhoto}= useContext(UserContext)
    const {firstName,lastName}= useContext(UserContext)
    const [isLoading,setIsLoading]=useState(false)
    const [file,setFile]=useState(null)
    const [fileType,setFileType]=useState('')
    const [image, setImage] = useState(null);
    const [appCountries,setAppCountries]=useState([])
    const [err,setErr]=useState(false)
    const [uploadPercentage, setUploadPercentage]=useState(0)
    const [isUploading, setIsUpLoading]=useState(false)
    const [pickedImage,setPickedImage]=useState(false)
    const [tempPhoto,setTempPhoto]=useState(usrPhoto)
      const [record,setRecord]=useState({
        FullName:'',
        VatNumber:'',
        Password:'',
        Email:'',
        Country:'',
        Telephone:'',
        telPrefix:'',
        EndDateDisplay:'1 2 3 4 '
    
        
      })
    const navigate=useNavigate()
    const fileInputRef=useRef()

    useEffect(()=>{
      
        //loadImage()
        loadProfile()
        loadCountries()
    },[])

    const loadProfile=()=>{
      //setIsLoading(true)
      axios.get(process.env.REACT_APP_API_SERVER+'manager/profile/'+sessionStorage.getItem('userToken'),{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
            }            
        })
      .then(res=>{

          setRecord(res.data.results[0])
          setIsLoading(false)
      }).catch(err=>{
          console.log(err)
          setIsLoading(false)
  
      }) 
  } 

  const loadCountries=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'manager/eshopcountries')
    .then(res=>{
      if (res.data.err===0){
  
          setAppCountries(res.data.results)
  
      }else{
        setErr(true)
      }
      setIsLoading(false)
    }).catch(xerr=>{
        setErr(true)
        setIsLoading(false)
    })          
  }


    const uploadFile=async (xfile)=>{
        setIsUpLoading(true)
        setPickedImage(false)
        const formData=new FormData()
        const imgNameArr=xfile.name.split('.')
        const ImgExtension=imgNameArr[imgNameArr.length-1]

    
        formData.append('file',xfile)

        formData.append('id',sessionStorage.getItem('ChatUserUUID'))
        formData.append('imgExtension',ImgExtension)

    
        axios.post(process.env.REACT_APP_API_SERVER+'userprofile/uploadmngphoto',formData,{
            headers: {
                'Authorization': 'Bearer '+sessionStorage.getItem("token"),
                'Content-Type':'multipart/form-data'
              },
              onUploadProgress: ProgressEvent=>{
                  setUploadPercentage(parseInt(Math.round(ProgressEvent.loaded*100/ProgressEvent.total)))
                 
              }            
          })
          .then(res=>{
    
              if (res.data.err==0){
               //setVideo(res.data.fileID)
               setUploadPercentage(0)
               setIsUpLoading(false)
                loadImage()
               //loadUserDetails()
              }else{
                console.log(res.data.msg)
              }
    
          }).catch(err=>{
            //console.log(err)
            alert("An error has occured, please try again")
          }) 
      }
    
    const selectMedia=()=>{
        fileInputRef.current.click();
    }

    const handleFileChange=(e)=>{
      try{
          if (e.target.files[0].type.includes('image')){
            setFile(e.target.files[0])
            setFileType('p')
            const reader = new FileReader();

            reader.onloadend = () => {
              setTempPhoto(reader.result);
              setPickedImage(true)
            };
        
            if (e.target.files[0]) {
              reader.readAsDataURL(e.target.files[0]);
            }
          }
            // if (e.target.files[0].type.includes('image')){
            //     setFileType('p')
            //     uploadFile(e.target.files[0])
            // }
        }catch(err){

        }  
    }

    const loadImage=()=>{
        const d=Date.now()
        //alert(`${process.env.REACT_APP_API_SERVER}files/${userID}/${d}`)
        setUsrPhoto(`${process.env.REACT_APP_API_SERVER}files/${sessionStorage.getItem('ChatUserUUID')}/${d}`)
        sessionStorage.setItem('usrPhoto',`${process.env.REACT_APP_API_SERVER}files/${sessionStorage.getItem('ChatUserUUID')}/${d}`)
    }

    const cancelUpload=()=>{
      try{
      fileInputRef.current.value=null;
      setIsUpLoading(false)
      setUploadPercentage(0)
      setTempPhoto(usrPhoto)
      setPickedImage(false)
      }catch(err){}
  }

  const countriesList=Object.entries(countries).sort((a,b)=>{
    if (a[1].name < b[1].name) {
        return -1;
    }
    if (a[1].name > b[1].name) {
        return 1;
    }
    return 0;       
  }).map((item,index)=>{
  // console.log(item)
    return(
        <option key={'country'+index} value={item[1].name}>{item[1].name}</option>

    )
  })



  const selectCountry=(countryName)=>{
  
    try{
      var telPrefix=Object.entries(countries).filter(item=>item[1].name===countryName)[0][1].phone[0]
    }catch(err){
       telPrefix=''
    }
    setRecord({...record,Country:countryName,telPrefix:telPrefix})
  
  }

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {   
    setShowPassword(!showPassword);
  };

  const transformDescr = (descr) => {
    if (!descr) return "";
    return descr
      .replace(/<li>/g, `<div style="display: flex; align-items: start;">
        <span style="margin-right:10px; font-size:1.2rem; color:#ff5d25"></span>
        <div style="display: inline-block;"> <strong>`)
      .replace(/<\/li>/g, `</strong></div></div>`);
  };


  const saveProfile=(e)=>{
    e.preventDefault()
    axios.post(process.env.REACT_APP_API_SERVER+'manager/profile',record,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("mngapiToken")
          }            
      })
    .then(res=>{
        if (res.data.err==0){
          loadProfile()
          sessionStorage.setItem('userName',record.FullName)
          alert('Profile Updated')
        }else{
          alert('An error has occured')
        }
        
        setIsLoading(false)
    }).catch(err=>{
        //console.log(err)
        setIsLoading(false)

    }) 

  }

    return (
        <>
          <br/>
          <Row><Col md={12}>
            <Alert variant="success">
                <b>Profile Settings</b>

            </Alert>
          </Col></Row>
          <Row>
              <Col md={4}>
                    <Alert variant="dark">Profile Photo</Alert>
                    <section className="pb-4 section-margin">
                    <div className="container container-mobile">

                            <br/><br/>
                    <p align="center">
                        <img src={tempPhoto} width="200"/>
                    </p>

                    <input 
                        type="file" 
                        ref={fileInputRef} 
                        onChange={handleFileChange} 
                        style={{ display: 'none' }} 
                    />  

                    <p align="center">
                    {!isUploading && (!pickedImage) &&
                    <>
                    <button type="button" class="btn btn-dark" onClick={selectMedia}>Select Photo</button>
                    <br/><br/>

                    </>
                    }

                    {pickedImage &&
                    <>
                      <button type="button" class="btn btn-success" onClick={()=>uploadFile(file)}>Upload</button>
                      <br/><br/>
                      <button type="button" onClick={()=>cancelUpload()} class="btn btn-secondary">Cancel</button>
                    </>
                    
                    }

                    {isUploading && 
                      <>
                      <div className="alert alert-secondary" role="alert">
                          Uploading {uploadPercentage}%
                      </div>  
                      <br/><br/>
                      <button type="button" onClick={()=>cancelUpload()} class="btn btn-secondary">Cancel</button>
                      </>
                    }


                    </p>
                    <br/><br/>

                </div>  


                </section>              
              </Col>

              <Col md={4}>
              <Alert variant="dark">Profile Details</Alert>
                <Card className="p-4 shadow-sm" style={{ maxWidth: "500px", width: "100%", borderRadius: "12px" }}>
                  {/* <h5 className="mb-3 text-center">Create your 123High account</h5> */}
                  <Form onSubmit={saveProfile}>

                    <Form.Group className="mb-3">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={record.FullName}
                        onChange={(e)=>setRecord({...record,FullName:e.target.value})}
                        required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>VAT</Form.Label>
                      <Form.Control
                        type="text"
                        name="vat"
                        value={record.VatNumber}
                        onChange={(e)=>setRecord({...record,VatNumber:e.target.value})}
                        required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Country</Form.Label>
                        <Form.Control required as="select" value={record.Country} onChange={(e)=>selectCountry(e.target.value)}>  
                          <option value=''>[Select Country]</option>{countriesList}
                        </Form.Control>
                      </Form.Group>            

                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={record.Email} 
                        onChange={(e)=>setRecord({...record,Email:e.target.value})}
                        required
                        disabled
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="tel"
                        name="phone"
                        value={record.Telephone} 
                        onChange={(e)=>setRecord({...record,Telephone:e.target.value})}
                        required
                        />
                    </Form.Group>

                      <Form.Group className="mb-3 position-relative">
                      <Form.Label>Password</Form.Label>

                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={record.Password} 
                          onChange={(e)=>setRecord({...record,Password:e.target.value})}
                          placeholder="Enter password"
                          minLength={6}
                          maxLength={12}
                          required
                          />
                          <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          onClick={togglePasswordVisibility}
                          style={{position:'absolute', right: "12px", top: '60%',color: 'gray'}}
                          />
                    </Form.Group>


                      <Button size="lg" className="w-100 mt-1 btn-payment" type="submit">
                      Save
                      </Button>
                  </Form>
                </Card>
              </Col>

              <Col md={4}>
                    <Alert variant="dark">Product Details</Alert>
                    <br/>
                    <table>
                      <tr>
                          <td><b>Product</b></td>
                          <td width="10"></td>
                          <td>{sessionStorage.getItem('ProductTitle')}</td>
                      </tr>

                      <tr>
                          <td><b>End Date</b></td>
                          <td width="10"></td>
                          <td>
                          {record.EndDateDisplay.split(' ')[2]} {record.EndDateDisplay.split(' ')[1]} {record.EndDateDisplay.split(' ')[3]}

                          </td>
                      </tr>
{/* 
                      <tr>
                          <td><b></b></td>
                          <td width="10"></td>
                          <td>
                          {record.appCountries} 

                          </td>
                      </tr> */}
                      
                    </table>
              </Col>              
          </Row>
     
        
        </>
    )
}
